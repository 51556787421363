<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          กติกาการแลกและได้รับคะแนน
        </h3>
      </div>

    </div>
    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      mypoint: 0,
      Loadmore: false,
      items: [],
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    getBalance() {
      this.Loadmore = true
      this.$http
        .get('balance/getbalance')
        .then(ress => {
          // console.log(ress.data)
          this.mypoint = ress.data.point
          localStorage.setItem('balance', JSON.stringify(ress.data.balance))
          this.Loadmore = false
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
  },
}
</script>

    <style scoped></style>
